import React, { useState } from "react";
import Accordion from "./components/Accordian";

function MainPage() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className='h-full w-full mt-8 mx-auto'>
      <div className='my-8'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 text-left'>
          <div>
            <div className='px-4 flex  w-full flex-col gap-8 py-10 border-1 border-vyellow border rounded-xl border-opacity-20   '>
              <h1 className='text-2xl items-center text-left flex-col md:flex-row gap-4  flex justify-between text-vyellow'>
                Staking
                <span className='text-xs tracking-wider font-bold'>
                  Flexible lock periods with high APY rates
                </span>
              </h1>
              For those looking for more stable and predictable returns, Fixed
              Period Staking offers the opportunity to lock in your assets for
              predetermined durations. This option is ideal for long-term
              investors seeking to maximize their APY and secure consistent
              returns over time.
              <Accordion
                title='Choose Chain'
                isOpen={openIndex === 0}
                onToggle={() => handleToggle(0)}
              >
                <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-vyellow  border-1 border border-vyellow text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='https://ethstaking.versx.io/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/5.svg'
                    />
                    ETHEREUM
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/3.svg'
                    />{" "}
                    BASE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/4.svg'
                    />
                    BINANCE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/2.svg'
                    />
                    AVALANCHE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/7.svg'
                    />
                    POLYGON
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/1.svg'
                    />
                    ARBITRUM
                  </a>{" "}
                </div>{" "}
                <p className='mt-4 -mb-2 text-white/60 text-sm'>
                  {" "}
                  Grayed chains are coming soon
                </p>
              </Accordion>
            </div>
          </div>
          <div>
            <div className='px-4 flex  w-full flex-col gap-8 py-10 border-1 border-vyellow border rounded-xl border-opacity-20   '>
              <h1 className='text-2xl items-center text-left flex-col md:flex-row gap-4  flex justify-between text-vyellow'>
                Daily Staking
                <span className='text-xs tracking-wider font-bold'>
                  No lock periods, earn extra rewards with referrals
                </span>
              </h1>
              Earn rewards on a daily basis with the flexibility of Daily
              Staking, which allows you to capitalize on short-term investments.
              Enhance your earnings further by participating in our referral
              program, where you can gain additional rewards by bringing new
              users.
              <Accordion
                title='Choose Chain'
                isOpen={openIndex === 1}
                onToggle={() => handleToggle(1)}
              >
                <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-vyellow  border-1 border border-vyellow text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='https://ethdailystaking.versx.io/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/5.svg'
                    />
                    ETHEREUM
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/3.svg'
                    />
                    BASE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/4.svg'
                    />
                    BINANCE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/2.svg'
                    />
                    AVALANCHE
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/7.svg'
                    />
                    POLYGON
                  </a>{" "}
                  <a
                    className='flex md:flex-row flex-col justify-center gap-2 rounded-md px-4 py-2 w-full md:w-auto text-center text-sm bg-white/30  border-1 border border-vgray text-black hover:bg-vgray border-1 hover:text-vyellow border hover:border-opacity-30 hover:border-vyellow'
                    href='#'
                  >
                    <img
                      className='rounded-full bg-black md:mx-0 mx-auto  w-5  h-5'
                      src='/chains/1.svg'
                    />
                    ARBITRUM
                  </a>{" "}
                </div>{" "}
                <p className='mt-4 -mb-2 text-white/60 text-sm'>
                  {" "}
                  Grayed chains are coming soon
                </p>
              </Accordion>
            </div>
          </div>
        </div>{" "}
      </div>{" "}
    </div>
  );
}

export default MainPage;
